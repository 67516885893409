<template>
  <div>
    <vs-input icon-after="true" :v-validate="vValidate" :data-vv-as="dataVvAs" :name="name" icon-pack="feather" icon="icon-search" readonly :label="label" class="w-full" v-model="nameBrowse" @click="browse"></vs-input>
    <input type="text" hidden :value="value">

    <vs-popup title="Find Unit" :active.sync="isPopUp" fullscreen>
      <vs-table :sst="true" search @search="handleSearch" :data="data.data" @sort="handleSort" class="mb-3" >
        <template slot="thead">
          <vs-th sort-key="code"></vs-th>
          <vs-th sort-key="bill_name">Billed To</vs-th>
          <vs-th sort-key="date_transaction">Date</vs-th>
          <vs-th sort-key="grand_total">Grand Total</vs-th>
          <vs-th sort-key="grand_total">Leftover</vs-th>
          <vs-th sort-key="cost_name">Cost Name</vs-th>
        </template>
        <template slot-scope="{data}">
          <vs-tr :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :data="tr.code">
              <div class="flex">
                <a class="pointer mr-3" @click="changeValue(tr.id)">{{tr.code}}</a>
                <vs-chip v-if="tr.job_order_cost_id" color="primary">Job Order Cost</vs-chip>
                <vs-chip v-if="tr.manifest_cost_id" color="success">Manifest Cost</vs-chip>
              </div>
            </vs-td>
            <vs-td label="Billed To" :data="tr.bill_name">{{tr.bill_name}}</vs-td>
            <vs-td label="Date" :data="tr.date_transaction">{{tr.date_transaction|moment('Do MMM YYYY')}}</vs-td>
            <vs-td label="Grand Total" :data="tr.grand_total">{{tr.grand_total|numFormat}}</vs-td>
            <vs-td label="Leftover" :data="tr.leftover">{{tr.leftover|numFormat}}</vs-td>
            <vs-td label="Cost Name" :data="tr.cost_name">{{tr.cost_name}}</vs-td>
          </vs-tr>
        </template>
      </vs-table>
      <vs-pagination :total="data.last_page" v-model="currentPage" class="mt-5"></vs-pagination>
    </vs-popup>
  </div>
</template>

<script>
import {mapState,mapActions} from 'vuex'
export default {
  props: ['value','label','vValidate','dataVvAs','name','params'],
  data(){
    return {
      nameBrowse: "",
      currentPage: 1,
      search:null,
      sortKey:null,
      sortVal:null,
      isPopUp:false
    }
  },
  watch:{
    async value(val) {
      if (val) {
        this.getValue(val)
      } else {
        this.nameBrowse = ""
      }
    },
    search(){
      this.currentPage=1
      this.getData()
    },
    currentPage(){
      this.getData()
    }
  },
  computed:{
    ...mapState({
      data: state => state.payables.rows
    })
  },
  methods:{
    async browse(){
      if (!this.params.vendor_id) {
        return this.$vs.notify({
          title: 'Oops!',
          text: 'Vendor must be filled',
          color: 'warning'
        })

      }
      await this.getData()
      this.isPopUp=true
    },
    changeValue(value){
      this.$emit('input', value)
      this.isPopUp=false
    },
    async getValue(value){
      let {data} = await this.dispatchShow(value)
      this.$emit('on-change', data)
      this.nameBrowse = `${data.bill_name} / ${data.date_transaction} / ${data.cost_name}`
    },
    ...mapActions({
      dispatchIndex: 'payables/index',
      dispatchShow: 'payables/show'
    }),
    async getData(){
      let payload = {
        page: this.currentPage,
        search: this.search,
        sortKey: this.sortKey,
        sortVal: this.sortVal,
        ...this.params
      }
      await this.dispatchIndex(payload)
    },
    handleSearch(val){
      this.search = val
    },
    handleSort(key,val){
      this.sortKey = key
      this.sortVal = val
      this.getData()
    }
  }
}
</script>

<style lang="scss">
  .pointer {
    cursor: pointer;
  }
</style>